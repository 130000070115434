<template>
  <div>
    <heads :backs="1" :msg="title_msg" :states="1"></heads>
    <div style="background:#fff;width:100%;overflow: hidden;">
      <div class="navIpt">
        <input id="" v-model="iptCode" placeholder="请输入兑换码" type="text" name="">
        <button :disabled="dhs" @click="changeMaterialObject">兑换领取</button>
      </div>
    </div>
    <div class="Tab">
      <div ref="piediv" class="mytab">
        <div class="mytab_content">
          <p
            v-for="(item, index) in tab_list"
            :key="index"
            :class="{ redBottom: active == index }"
            @click="checkedMyTab(index)"
          >
            <span>{{ item.title }}</span>
          </p>
        </div>
      </div>
    </div>
    <div v-show="active==0" class="exchangeList">
      <div v-for="(item,index) in list" :key="index" class="exchangeListItem">
        <div class="itemTOP">
          <img :src="item.thumb" alt="">
          <p>{{ item.product_name }}</p>
        </div>

        <div class="itemBOTTOM">
          <p>使用有效期至：<span>{{ item.valid_end }}</span></p>
          <p id="copy_text" :data-clipboard-text="dhCode" @click="copy(item.redeem_code)">复制兑换码</p>
        </div>
      </div>
      <div v-show="show_list" class="nodatas">
        <img src="@/static/images/my_index/kong.png" alt="kong">
        <p>您还没有可使用的兑换码~</p>
      </div>
    </div>
    <div v-show="active==2" class="exchangeList">
      <!-- <div class="exchangeListItem">
        <div class="itemTOP">
          <img src="" alt="">
          <p>了社恐了觉得覅恐怕是的发覅哦陪你 就买到皮肤</p>
        </div>
        <div class="itemBOTTOM">
          <p>使用有效期限：<span></span></p>
          <p>复制兑换码</p>
        </div>
      </div> -->
      <div v-for="(item,index) in list" :key="index" class="exchangeListItem grayStyle">
        <div class="itemTOP">
          <img :src="item.thumb" alt="">
          <p>{{ item.product_name }}</p>
        </div>
        <div class="itemBOTTOM">
          <p>使用有效期至：<span>{{ item.valid_end }}</span></p>
          <!-- <p id="copy_text1" :data-clipboard-text="dhCode" @click="copy1(item.redeem_code)">复制兑换码</p> -->
        </div>
        <img class="iv_tag" src="../../static/ucenter-qie/已过期图章@3x.png" alt="">

      </div>
      <div v-show="show_list" class="nodatas">
        <img src="@/static/images/my_index/kong.png" alt="kong">
        <p>您还没有已过期的兑换码~</p>
      </div>
    </div>
    <div v-show="active==1" class="exchangeList">
      <!-- <div class="exchangeListItem">
        <div class="itemTOP">
          <img src="" alt="">
          <p>了社恐了觉得覅恐怕是的发覅哦陪你 就买到皮肤</p>
        </div>
        <div class="itemBOTTOM">
          <p>使用有效期限：<span></span></p>
          <p>复制兑换码</p>
        </div>
      </div> -->
      <div v-for="(item,index) in list" :key="index" class="exchangeListItem grayStyle">
        <div class="itemTOP">
          <img :src="item.thumb" alt="">
          <p>{{ item.product_name }}</p>
        </div>
        <div class="itemBOTTOM">
          <p>使用有效期至：<span>{{ item.valid_end }}</span></p>
          <!-- <p id="copy_text1" :data-clipboard-text="dhCode" @click="copy1(item.redeem_code)">复制兑换码</p> -->
        </div>
        <img class="iv_tag" src="../../static/ucenter-qie/已使用图章@3x.png" alt="">

      </div>
      <div v-show="show_list" class="nodatas">
        <img src="@/static/images/my_index/kong.png" alt="kong">
        <p>您还没有已使用的兑换码~</p>
      </div>
    </div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="block" @click.stop>
          <p class="poptitle" @click>选择地址</p>
          <div class="addAddress" @click="go_addressList()">
            <span>+</span> 请添加地址
          </div>
          <div class="confirms">
            <div @click="show = false">
              取消
            </div>
            <div>
              确认
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="HaveAddressShow" @click="HaveAddressShow = false">
      <div class="wrapper">
        <div class="block">
          <p class="poptitle">选择地址</p>
          <div class="addAddress1" @click="go_addressList()">
            <div>
              <span class="addName">{{ AddressInfo.receiver }}</span>
              <span class="addPhone">{{ AddressInfo.mobile }}</span>
            </div>
            <div class="addressDetailarr">
              <div class="addressDetail">
                <p>{{ AddressInfo.province }}{{ AddressInfo.city }}{{ AddressInfo.county }}{{ AddressInfo.detail_address }}</p>
              </div>
              <div class="arrwerRight">
                <img src="../../static/images/my_index/jiantou.png" alt="">
              </div>
            </div>
          </div>
          <div class="confirms">
            <div @click="HaveAddressShow = false">
              取消
            </div>
            <div @click="go_exchanges()">
              确认
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="successShow" @click="show = false">
      <div class="wrapper">
        <div class="block1" @click.stop>
          <!-- <p class="poptitle" @click.stop>兑换成功</p> -->
          <div class="imgs">
            <img v-if="errNo==0" src="../../static/images/my_index/changeSuccess.png" alt="">
            <img v-if="errNo!=0" src="../../static/images/my_index/changeError.png" alt="">
            <!-- <p>{{ `${pct_name.substr(0,12)}...` }}</p> -->
            <p>{{ changeText }}</p>
          </div>
          <div class="confirms1">
            <div @click="successShow = false">
              关闭
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 遮罩等待 -->
    <loadingView v-show="exchange_loading" bgcolor="rgba(0,0,0,0.2)" />
  </div>
</template>
<script>
import heads from '@/compontens/heads.vue'
import loadingView from '@/compontens/loadingView.vue'
import { redeemlist, redeem, productinfo, get_list } from '@/api/my_user.js'
import { Toast } from 'vant'
import Clipboard from 'clipboard'
export default {
  components: {
    heads,
    loadingView
  },
  data() {
    return {
      show_list: false,
      title_msg: '我的兑换码',
      active: 0,
      show: false,
      HaveAddressShow: false,
      successShow: false,
      AddressInfo: '',
      redeem_code: '',
      dhCode: '',
      iptCode: '',
      isnext: false,
      addlist: '',
      dhs: false,
      thumb: '',
      product_type: '',
      pct_name: '',
      types: 1,
      list: [],
      tab_list: [
        { title: '可使用' },
        { title: '已使用' },
        { title: '已过期' }
      ],
      changeText: '',
      errNo: '',
      exchange_loading: false
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    if (this.$route.query.AddressInfo) {
      this.AddressInfo = JSON.parse(this.$route.query.AddressInfo)
      this.addressId = this.AddressInfo.id
      this.HaveAddressShow = true
    }
    if (this.$route.query.codeadd) {
      this.iptCode = this.$route.query.codeadd
      this.dhCode = this.$route.query.codeadd
      this.pct_name = this.$route.query.pct_name
      this.thumb = this.$route.query.thumb
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  },
  methods: {
    copy(code) {
      this.dhCode = code
      var clipboard = new Clipboard('#copy_text')

      clipboard.on('success', (e) => {
        console.log(e.text)
        Toast('已复制兑换码到剪切板')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        // 释放内存
        clipboard.destroy()
      })
    },
    copy1(code) {
      this.dhCode = code
      var clipboard = new Clipboard('#copy_text1')

      clipboard.on('success', (e) => {
        console.log(e.text)
        Toast('已复制兑换码到剪切板')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        // 释放内存
        clipboard.destroy()
      })
    },
    go_exchanges() {
      this.exchange_loading = true
      var params = {
        code: this.iptCode,
        address_id: this.addressId
      }
      redeem(params).then(res => {
        this.errNo = res.errNo
        if (res.errNo == 0) {
          // Toast('兑换成功')
          this.exchange_loading = false
          if (!this.exchange_loading) {
            this.successShow = true
          }
          this.changeText = '恭喜您兑换成功！'
          this.getList()
        } else {
          this.exchange_loading = false
          if (!this.exchange_loading) {
            this.successShow = true
          }
          this.changeText = res.msg
          // Toast(res.result)
        }
      })
    },
    getList() {
      this.list = []
      var params = {
        type: this.types
      }
      redeemlist(params).then(res => {
        this.list = res.result.list
        // this.list = [{"product_name":123,"thumb":"https://newoss.zhulong.com/forum/202201/12/31/153731yi3lmnf5hmmab8hy.jpg?x-oss-process=image/resize,m_fill,w_424,h_320"},{"product_name":123,"thumb":"https://newoss.zhulong.com/forum/202201/12/31/153731yi3lmnf5hmmab8hy.jpg?x-oss-process=image/resize,m_fill,w_424,h_320"}]
        if (this.list.length < 1) {
          this.show_list = true
        }
      })
    },
    go_addressList() {
      if (this.product_type == 2) {
        this.$router.push({
          path: '/jdAddressList',
          query: {
            type: 'exchangeCode',
            code: this.dhCode,
            pct_name: this.pct_name,
            thumb: this.thumb
          }
        })
        return false
      }
      this.$router.push({
        name: 'address_management',
        query: {
          type: 'exchangeCode',
          code: this.dhCode,
          pct_name: this.pct_name,
          thumb: this.thumb
        }
      })
    },
    checkedMyTab(index) {
      this.show_list = false
      this.list = []
      this.active = index
      if (index == 0) {
        this.types = 1
      } else if (index == 1) {
        this.types = 3
      } else {
        this.types = 2
      }
      this.getList()
    },
    changeMaterialObject() {
      this.exchange_loading = true
      // debugger
      var params = {
        code: this.iptCode
      }
      productinfo(params).then(res => {
        this.errNo = res.errNo
        if (res.errNo !== 0) {
          // Toast(res.result)
          this.exchange_loading = false
          if (!this.exchange_loading) {
            this.successShow = true
          }
          this.changeText = res.msg
        } else {
          this.pct_name = res.result.product_name
          this.thumb = res.result.thumb
          this.product_type = res.result.product_type
          console.log(this.product_type)
          if (this.product_type == 1 || res.result.must_send == 1) {
            this.exchange_loading = false
            // 获取地址列表
            get_list().then(res => {
              res.result.list.forEach(addressitem => {
                if (addressitem.is_default == 1) {
                  this.AddressInfo = addressitem
                  this.addressId = addressitem.id
                  this.HaveAddressShow = true
                  this.isnext = true
                }
              })
              if (this.isnext == false) {
                this.show = true
              }
            })
            return
          }
          if (this.$route.query.AddressInfo &&
                  this.product_type !== 0 &&
                  this.product_type !== 3 &&
                  this.product_type !== 4 &&
                  this.product_type !== 2) {
            console.log(this.product_type, 'this.product_type')
            this.exchange_loading = false
            this.HaveAddressShow = true
          } else if (this.product_type == 2) {
            this.exchange_loading = false
            this.show = true
          } else if (this.product_type == 0 || this.product_type == 3 || this.product_type == 4) {
            this.dhs = true
            this.go_exchanges()
            setTimeout(() => {
              this.dhs = false
            }, 1500)
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.nodatas {
  margin-top: 250px;
  text-align: center;
  p {
    color: #333;
    font-size: 26px;
    margin-top: 20px;
  }
}
.imgs{
  width: 100%;
  img{
     width: 200px;
  // height: 188px;
  display: block;
  // background: #d2d2d2;
  margin: 0 auto;
  margin-top: 27px;
  }
  p{
    text-align: center;
    font-size: 32px;
    padding-top: 32px;
    color: #333;
  }
}
.arrwerRight img{
  width: 16px;
  height: 24px;
        padding: 32px 0 0 66px;

}
.addressDetailarr{
  display: flex;
}
.addressDetail{
    font-size: 28px;
    color: #666;
    width: 388px;
        padding: 26px 0 0 36px;

}
.addName{
    font-size: 30px;
    display: inline-block;
    padding: 26px 0 0 36px;
    font-weight: 600;
    color: #333;
}
.addPhone{
    font-size: 30px;
    padding-left: 15px;
    font-weight: 600;
    color: #333;

}
.confirms1 div:nth-child(1){
    // width: 100%;
    width: 100px;
    height: 60px;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    line-height: 60px;
    background: #ee2e2e;
    color: #fff;
    border-radius: 6px;
}
.confirms div:nth-child(1){
    width: 50%;
    height: 102px;
    border-right: 1px solid #d2d2d2;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 102px;
    color: #333;
}
.confirms div:nth-child(2){
    width: 50%;
    height: 102px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 102px;
    color: #ee2e2e;
}
.confirms1{
width: 100%;
// width: 70px;
// height: 32px;
position: absolute;
bottom: 30px;
// border-top: 1px solid #d2d2d2;
display: flex;
justify-content: center;
}
.confirms{
width: 100%;
height: 102px;
position: absolute;
bottom: 0;
border-top: 1px solid #d2d2d2;
display: flex;
}
.addAddress1{
    width: 100%;
    height: 216px;
}
.addAddress{
    width: 100%;
    height: 216px;
    line-height: 216px;
    text-align: center;
    color: #ee2e2e;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 2px;
}
.poptitle{
    font-size: 32px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #333;
    text-align: center;
    padding-top: 38px;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 566px;
    height: 405px;
    background-color: #fff;
    position: relative;
    border-radius: 12px;

  }
  .block1{
    width: 566px;
    height: 420px;
    padding-top:40px ;
    background-color: #fff;
    position: relative;
    border-radius: 12px;
  }
.itemBOTTOM{
    display: flex;
    justify-content: space-between;

}
.itemBOTTOM p:nth-child(1){
    margin: 15px 0 0 28px;

}
.itemBOTTOM p:nth-child(2){
    margin: 15px 36px 0 0;
    color: #F40E0E;
}
.grayStyle{
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}
.itemTOP{
  position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 20px;
}
.iv_tag{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 120px;
  height: 120px;
}
.itemTOP img{
    width: 160px;
    height: 116px;
    margin: 22px 0 0 24px;
}
.itemTOP p{
    color: #333;
    font-size: 30px;
    width: 430px;
    margin: 22px 30px 0 0;
}
.exchangeList{
    width: 100%;
    .exchangeListItem{
        width: 692px;
        min-height: 228px;
        background: #fff;
        margin: 0 auto;
        margin-top: 40px;
        padding-bottom: 10px;
    }
}
.mytab{
    background: #fff;
}
.mytab_content {
  width: 57%;
  height: 90px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  line-height: 90px;
  .redBottom {
    width: 100px;
    border-bottom: 5px solid #ee2e2e;
    text-align: center;
    span{
    color: #ee2e2e;

    }
  }
  p {
    width: 100px;
    text-align: center;
    span {
      font-size: 28px;
      color: #333;
    }
  }
}
.navIpt{
    margin: 0 auto;
    margin-top: 30px;
    width: 690px;
    height: 76px;
    background: #F3F3F3;
    border-radius: 42px;
    display: flex;
    justify-content: space-between;
    background: fff;
    input{
        color: #999;
        font-size: 30px;
        background: #F3F3F3;
        // background: red;
        height: 76px;
        border-radius: 42px;
        padding-left:40px
    }
    button{
        border-radius:0 42px 42px 0;
        width: 184px;
        height: 76px;
        background: #EE2E2E;
        color:#fff;
        font-size:30px;
    }
}
</style>
